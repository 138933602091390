<template>
  <v-select
    v-model="industry"
    :items="industries"
    :error-messages="industryErrors"
    :required="required"
    :hint="hint"
    :persistent-hint="persistentHint"
    item-text="name"
    item-value="_id"
    return-object
    :menu-props="{ offsetY: true }"
    label="Indústria"
    outlined
    @change="$v.industry.$touch()"
    @blur="$v.industry.$touch()"
  />
</template>

<script>
import { mapState } from 'pinia'
import { useIndustryStore } from '@/stores'
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'SelectIndustry',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    hint: {
      type: String,
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    industry: {}
  }),
  computed: {
    ...mapState(useIndustryStore, ['industries']),

    industryErrors() {
      const errors = []
      if (!this.$v.industry.$dirty) return errors
      !this.$v.industry.required && errors.push('Indústria é obrigatória.')
      return errors
    }
  },
  watch: {
    industry(newValue, oldValue) {
      this.$emit('input', newValue)
    }
  },
  mounted() {
    this.industry = this.value
  },
  methods: {
    valid() {
      this.$v.$touch()
      return !this.$v.$invalid
    }
  },
  validations: {
    industry: {
      required: requiredIf(function() {
        return this.required
      })
    }
  }
}
</script>
